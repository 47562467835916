<template>
  <v-container>
    <v-card class="card">
      <v-card-title>
        Transacciones
        <v-btn @click="exportData" class="ml-3">Exportar</v-btn>
        <v-spacer></v-spacer>
        <v-row>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-bind="attrs"
                  v-on="on"
                  v-model="dateRangeText"
                  label="Filtrar por fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                ></v-text-field>
              </template>
              <v-date-picker
                @change="onRangeChange()"
                v-model="range"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle class="text-center"
        >👋 Mostrando transacciones de <b>{{ range[0] }}</b
        >, 6:01:00 PM al <b>{{ range[1] }}</b
        >, 6:00:00 PM!</v-card-subtitle
      >

      <div v-if="search">
        <v-card-subtitle
          style="color: rgb(218, 79, 79)"
          class="m-0 pt-0 text-center"
        >
          Filtro:
          <b>{{ search }}</b>
        </v-card-subtitle>
      </div>
      <v-data-table
        :headers="headers"
        :items="transactions"
        :search="search"
        :custom-filter="filterItems"
      ></v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { formatCurrency, renewSession } from "@/utils";
import { AUTH_HEADER } from "@/config/constants";
export default {
  data() {
    return {
      search: "",
      range: [
        this.formatDate(new Date().valueOf()),
        this.formatDate(new Date().valueOf()),
      ],
      menu: false,
      headers: [
        {
          text: "Nombre de Cliente",
          align: "start",
          value: "payer.name",
        },
        { text: "Email de Cliente", value: "payer.email" },
        { text: "Telefono de Cliente", value: "payer.phone" },
        { text: "Cobrado con Comision", value: "depositedAmount" },
        { text: "Monto Original", value: "amount" },
        { text: "Comision", value: "commission" },
        { text: "Estado", value: "status" },
        { text: "Fecha", value: "date" },
        { text: "No De Pago", value: "order" },
        { text: "No De Aprobacion", value: "approvalCode" },
      ],
      transactions: [],
    };
  },
  computed: {
    dateRangeText() {
      return this.range.join(" ~ ");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/transactions/?startDate=${this.range[0]}&endDate=${this.range[1]}`,
        {
          headers: {
            [AUTH_HEADER]: localStorage.getItem("session"),
          },
        }
      );

      const { ok, data, errors, errorMessage } = await resp.json();
      if (ok) {
        this.transactions = data.map((x) => ({
          ...x,
          commission: `${x.commission}%`,
          amount: formatCurrency(x.amount),
          depositedAmount: formatCurrency(x.depositedAmount),
        }));
      }
      if (!ok) {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    async exportData() {
      const resp = await fetch(
        `${process.env.VUE_APP_API_URL}/reports/transactions/me/${this.range[0]}/${this.range[1]}/?format=file&search=${this.search}`,
        {
          headers: {
            [AUTH_HEADER]: localStorage.getItem("session"),
          },
        }
      );

      const blob = await resp.blob();
      if (blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        const filename = this.search
          ? `yoyo-filtered-transactions '${this.search}' ${this.dateRangeText}.csv`
          : `yoyo-transactions ${this.dateRangeText}.csv`;
        a.download = filename;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        setTimeout(() => URL.revokeObjectURL(link.href), 250);
        renewSession(resp);
      } else {
        if (errorMessage) this.error = errorMessage;
        else if (errors) {
          this.error = errors[0].msg;
        } else {
          this.error = resp.statusText;
        }
      }
    },
    onRangeChange() {
      this.menu = false;
      this.fetchData();
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    filterItems(value, search, item) {
      search = search.toLowerCase()
      if (
        item.payer.name.toLowerCase().includes(search) ||
        item.payer.email.toLowerCase().includes(search) ||
        item.payer.phone.toLowerCase().includes(search)
      )
        return true;
    },
  },
};
</script>
